* {
  margin: 0;
  padding: 0;
}

.audio-player {
  background-color: rgb(44, 43, 43);
  width: auto;
  flex-direction: column;
  align-items: left;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-family: sans-serif;
  padding-left: 10px;
  padding-right: 10px;
}

.track-name {
  margin-bottom: 3px;
  margin-top: 5px;
  font-family: "Helvetica";
  font-size: 1.7rem;
}

.track-album {
  opacity: 70%;
}

.track-progress {
  display: flex;
  width: 100%;
}

.progress {
  width: 100%;
  margin-left: 7px;
  margin-right: 7px;
  -webkit-appearance: none;
  border-style: none;
  height: 5px;
}

.progress:focus {
  outline: none;
}

.progress::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  /* background: #000000; */
}

.progress::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  /* border: 1px solid #2497e3; */
  height: 8px;
  width: 8px;
  border-radius: 25px;
  background: #77bafd;
  /* cursor: pointer; */
  -webkit-appearance: none;
  margin-top: -1px;
}

.progress:focus::-webkit-slider-runnable-track {
  /* background: #666666; */
}

.track-actions {
  width: 100%;
  margin-top: 50px;
  justify-content: space-around;
}

.track-actions button {
  padding: 5px 20px;
}


.word-scroller {
  height: 40px; /* Adjust as needed */
  overflow: hidden;
}

.word {
  transition: transform 1s ease-in-out;
}

.word-item {
  height: 40px; /* Must be the same as .word-scroller height */
  display: flex;
  align-items: center;
  justify-content: center;
}
